<template>
  <v-card>
    <v-card-title>
      {{ $t('$vuetify.clientStepper.addCycleModal.heading') }}
    </v-card-title>

    <v-card-text>
      <v-form
          ref="addCycleForm"
      >
        <v-col class="d-flex" cols="12" sm="6">

          <v-select
              :items="categories"
              v-model="title"
              :label="$t('$vuetify.clientStepper.addCycleModal.categorySession')"
              outlined
              dense
              :rules="[rules.requiredProblemCategory]"
          ></v-select>
        </v-col>

        <v-textarea
            v-model="description"
            outlined
            :placeholder="$t('$vuetify.clientStepper.addCycleModal.problemDescriptionPlaceHolder')"
            :label="$t('$vuetify.clientStepper.addCycleModal.problemDescription')"
            :rules="[rules.requiredRule]"
        ></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="dialog">
      <v-btn class="primary" @click="addNewCycle">{{$t('$vuetify.clientStepper.confirm')}}</v-btn>
      <v-btn class="red white--text" @click="close">{{$t('$vuetify.clientStepper.close')}}</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import {mapGetters} from 'vuex';


export default {
  props: {
    dialog: {
      default: false
    },
    getProfile: {
      default: true
    },
    addCycleAction: {
      default: 'addNewCycle'
    },
    isAdmin: {
      default: false
    },
    clientId: {
      default: null
    }
  },
  data() {
    return {
      title: null,
      description: null,
      categories: [
        this.$t('$vuetify.clientStepper.addCycleModal.categories[0]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[1]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[2]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[3]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[4]'),
        this.$t('$vuetify.clientStepper.addCycleModal.categories[5]'),
      ],
      categoryMap: [
        {title:this.$t('$vuetify.clientStepper.addCycleModal.categories[0]'), cat: 'cat_1'},
        {title:this.$t('$vuetify.clientStepper.addCycleModal.categories[1]'), cat: 'cat_2'},
        {title:this.$t('$vuetify.clientStepper.addCycleModal.categories[2]'), cat: 'cat_3'},
        {title:this.$t('$vuetify.clientStepper.addCycleModal.categories[3]'), cat: 'cat_4'},
        {title:this.$t('$vuetify.clientStepper.addCycleModal.categories[4]'), cat: 'cat_5'},
        {title:this.$t('$vuetify.clientStepper.addCycleModal.categories[5]'), cat: 'cat_6'},
      ],
      rules: {
        requiredRule: v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired'),
        requiredProblemCategory: v => !!v || this.$t('$vuetify.addNewCycle.errors.selectIsRequired'),
        lengthRule: v => v.length >= 20 || this.$t('$vuetify.addNewCycle.errors.minChar'),

      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getUserInfo'
    })
  },
  created() {
    EventBus.$on('postConsultationInfoEvent', this.addNewCycle);
  },
  beforeDestroy() {
    EventBus.$off('postConsultationInfoEvent', this.postConsultation);
  },
  methods: {
    addNewCycle() {

      if (this.$refs.addCycleForm.validate()) {
        let payload;

        // if (this.profile.cycles.length == 0) {
        //     payload.requestFirstSession = true;
        // }
        if (this.isAdmin) {
          payload = {
            id: this.clientId,
            payload: {
              title: this.title,
              description: this.description,
            }
          }
        } else {
          payload = {
            title: this.title,
            description: this.description,
          };
        }
        let catMap = this.categoryMap.find(item => item.title == this.title);

        this.$store.dispatch(this.addCycleAction, payload).then(() => {

          if (catMap != undefined) {
            let data = {
              cycle_category: catMap.cat
            };
            window.webengage.track('cycle_added', data);
          }

          EventBus.$emit('notify', 'green', undefined, this.$t('$vuetify.Notify.successfully'));
          if (this.getProfile) {
            this.$store.dispatch('getUserInfo').then(() => {
              this.$emit('cycleAdded');
              this.close();
            });
          } else {
            this.$emit('cycleAdded');
            this.close();
          }


        });
      } else {
        Event.$emit('postConsultationInfoError');
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>